<mat-form-field [floatLabel]="'always'" class="dh-form-field">
  <mat-label *ngIf="label"> {{label}} </mat-label>
  <div [matTooltipDisabled]="isTooltipDisable"  matTooltip="{{'message.field.tooltip.'+ formControlName | translate}}" style="display: flex; flex-direction: row;" >
    <input matInput type="{{type}}" value={{controller.value}} [formControl]="controller" [placeholder]="placeholder | translate"
           (change)="onChange($event)" (input)="onInput($event)" (blur)="onBlur($event)" [maxlength]="allowTypingMaxLength" #input
           [ngStyle]="{'text-align': alignText }" />
    <i azure-id="visibility" class="material-icons" style="cursor: pointer" *ngIf="isPasswordField === true" (click)="toggle()"> {{ type === 'password' ? 'visibility_off' : 'visibility'  }}</i>

          </div>
  <mat-hint
            *ngIf="(controller.valid && shouldShowHint) || (shouldShowHint && !controller.touched)"
            align="end">{{input.value?.length || 0}}/{{maxLength}}
  </mat-hint>
  <span matSuffix>{{suffix}}</span>
  <mat-error [ngClass]="{'row':shouldShowHint}" *ngIf="!controller.valid && controller.touched">
    <app-validation-errors-message
      [ngClass]="{'col-lg-7 float-left':shouldShowHint}"
      [errors]="controller.errors"
      [validations]="metadata?.validations">
    </app-validation-errors-message>
    <mat-hint
      align="end"
      class="col-lg-5 float-right text-right mat-hint-error"
      *ngIf="shouldShowHint">
      {{input.value?.length || 0}}/{{maxLength}}
    </mat-hint>
  </mat-error>
</mat-form-field>
