import {Component} from '@angular/core';
import {ProfileFormTemplateProviders} from '../../../core/templates/profile-form.template.providers';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";
import {
  BFBeforeProfileView,
  BFOnFormLoadComplete,
  BFOnValueChange
} from "../../../core/interfaces/sdk-profile-hooks.interface";
import {IValueChange} from "../../../core/interfaces/form-value-change.interface";
import {Caller} from "../../models/caller.model";
import {BFEvents, Helper} from "@fgpp-ui/components";
import {ProfileLayoutType} from "../../enums/profile-layout-type.enum";

@Component({
  selector: 'app-callers',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class CallerComponent extends AbstractProfileFormComponent implements BFOnFormLoadComplete, BFOnValueChange, BFBeforeProfileView {

  fgppBeforeView(): void {
    this.layoutEvent.disable('AUTH_TOOLBAR:CREATE_PASSWORD');
  }

  fgppFormLoadComplete(): void {
    const authType = this.formMgr.get(Caller.AUTH_TYPE).getValue();
    this.disableCreatePasswordBtn(authType);
    this.checkPasswordValidation(authType);
    const superCaller = this.formMgr.get(Caller.SUPER_CALLER_IND).getValue();

    if (this.layoutMode !== ProfileLayoutType.VIEW) {
      this.disablePartyCode(superCaller);
      this.disableMinCallbackAmount(superCaller);
    }
  }

  private disableCreatePasswordBtn(authType: string): void {
    if (['D', 'E', 'V'].includes(authType)) {
      this.layoutEvent.enable('AUTH_TOOLBAR:CREATE_PASSWORD');
    } else {
      this.layoutEvent.disable('AUTH_TOOLBAR:CREATE_PASSWORD');
    }
  }

  private disablePartyCode(superCaller: string) {
    const partyCode = this.formMgr.get(Caller.CUST_CODE);
    const partyName = this.formMgr.get(Caller.CUST_NAME);
    if (superCaller === '1') {
      partyCode.disable().reset().markAsOptional();
      partyName.disable().reset();
      partyCode.setValue('');
    } else {
      partyCode.enable().markAsRequired();
    }
  }

  private disableMinCallbackAmount(superCaller: string) {
    const minAmount = this.formMgr.get(Caller.SUPER_CALLER_CALLBACK_MIN_AMT);
    if (superCaller !== '1') {
      minAmount.disable().reset();
    } else {
      minAmount.enable();
    }
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === Caller.AUTH_TYPE) {
      const authType = this.formMgr.get(Caller.AUTH_TYPE).getValue();
      this.disableCreatePasswordBtn(authType);
      this.checkPasswordValidation(authType);
    }
    if (change.control === Caller.SUPER_CALLER_IND) {
      const superCaller = this.formMgr.get(Caller.SUPER_CALLER_IND).getValue();
      this.disableMinCallbackAmount(superCaller);
      this.disablePartyCode(superCaller);
    }
    if (change.control === Caller.CUST_CODE) {
      const callerDetails = this.getDrilldownData(Caller.CUST_CODE);
      this.populateCallerDetails(callerDetails);
    }
  }

  protected populateCallerDetails(partyDetails: any) {
    const partyName = partyDetails ? partyDetails[Caller.CUST_NAME] : '';
    if (partyName) {
      this.formMgr.get(Caller.CUST_NAME).setValue(partyName);
    }
  }

  protected checkPasswordValidation(authType: string) {
    if (['D', 'E', 'V'].includes(authType)) {
      this.formMgr.get(Caller.CALLER_PASSWORD).markAsRequired();
    } else {
      this.formMgr.get(Caller.CALLER_PASSWORD).markAsOptional().reset();
    }
  }

  protected buttonClickHandler(actionPayload: BFEvents.ButtonEvent) {
    super.buttonClickHandler(actionPayload);
    if (actionPayload.name === 'buttonClick' && actionPayload.target === 'AUTH_TOOLBAR:CREATE_PASSWORD') {
      const password = Helper.randomString(6);
      this.formMgr.get(Caller.CALLER_PASSWORD).setValue(password);
      this.formMgr.get(Caller.CALLER_PASSWORD).markAsDirty();
    }
  }

}
