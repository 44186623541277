import {
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  Renderer2,
  ViewChild,
  AfterContentInit, ChangeDetectionStrategy, OnInit, Optional, AfterViewInit
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FaFormComponent } from '../fa-form/fa-form.component';
import { AbstractFormControlInputComponent } from '../abstract-form-control-input/abstract-form-control-input.component';
import { FieldVerifications, FieldVerificationsService } from '../../services/field-verifications.service';
import { DhValidationErrorsMessageComponent } from '../validation-errors-message/validation-errors-message.component';

@Component({
  selector: 'app-dh-input',
  templateUrl: './dh-input.component.html',
  styleUrls: ['./dh-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DhInputComponent,
            multi: true
        }
    ]
})
export class DhInputComponent extends AbstractFormControlInputComponent implements OnInit, AfterContentInit, AfterViewInit {
  @ViewChild(DhValidationErrorsMessageComponent, { static: true }) dhValidationErrorsMessageComponent: DhValidationErrorsMessageComponent;
  @Input() isTooltipDisable = false;
  @Input() label: string;
  @Input() description: string;
  @Input() formControlName: string;
  @Input() type: string;
  @Input() isPasswordField = false;
  @Input() suffix;
  @Input() alignText = 'left';
  // public hide = false;
  @Input() placeholder: string;

  constructor(@Inject(forwardRef(() => ControlContainer)) form: ControlContainer,
              @Optional() @Inject(forwardRef(() => FaFormComponent)) faFormComponent: FaFormComponent,
              renderer: Renderer2,
              protected elem: ElementRef,
              fieldVerificationsService: FieldVerificationsService) {
    super(form, faFormComponent, renderer, elem, fieldVerificationsService);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.type == null) {
      this.type = 'text';
    }
  }

  ngAfterContentInit() {
    if (!this.controller.disabled && this.metadata && this.metadata.verification === FieldVerifications.Rekey) {
      const labelElem = this.formControl.getElementsByClassName('mat-form-field-label-wrapper')[0];
      this.renderer.setAttribute(labelElem, 'inputValue', '*'.repeat(this.controller.value.toString().length));
    }

    if (!this.controller.disabled && this.metadata && this.metadata.verification === FieldVerifications.Blind) {
      const labelElem = this.formControl.getElementsByClassName('mat-form-field-label-wrapper')[0];
      this.renderer.setAttribute(labelElem, 'inputValue', '*'.repeat(this.controller.value.toString().length));
    }
  }

  onChange(value) {
    this.onInputChange();
    this.change.emit(value);
  }

  toggle() {
    // this.hide = !this.hide;
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', this.id);
  }
}
